import React from "react"
import SEO from "../components/seo"
import Layout from '../containers/layout/layout'
import Header from '../containers/layout/header/header-one'
import Footer from '../containers/layout/footer/footer-one'
import ServicesArea from '../containers/it-services/services-area'
import { useStaticQuery, graphql, use } from "gatsby"

const ITServicePage = ({ pageContext, location }) => {
  const featuredDataQuery = useStaticQuery(graphql `
      query ITServicesQueryData {
          allItServicesJson(sort: {order: ASC, fields: order}, filter: {type: {eq: "it-design"}}) {
              edges {
                node {
                  fields {
                      slug
                  }
                  id
                  title
                  excerpt
                  image {
                      childImageSharp{
                          fixed(width:370, height: 200, quality: 100 ){
                              ...GatsbyImageSharpFixed_withWebp
                          }
                      }
                  }
                }
              }
          }
      }
  `); 
  const featureData = featuredDataQuery.allItServicesJson.edges;
  return (
    <Layout location={location}>
      <SEO title="IT Services" />
      <Header/>
      <main className="site-wrapper-reveal">
          <ServicesArea
            title="For your very specific industry, <br/> we have <span>highly-tailored IT solutions</span>"
            subtitle="IT DESIGN"
            data={featureData}
          />
      </main>
      <Footer/>
    </Layout>
  )
}
 
export default ITServicePage
 